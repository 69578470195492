<template>
	<div class="Shop">
		
		<div class="Top">
			<div class="Left">
				店铺详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回店铺列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li v-if="Shop != null && Shop.Id != 'add'">
					<span>店铺ID</span>
					<em>
						{{Shop.Id}}
					</em>
				</li>
				
				<li>
					<span>店铺名</span>
					<em  v-if="Shop != null">
						<el-input v-model="Shop.Name" style="width: 200px;"></el-input>
					</em>
				</li>
				
				<li>
					<span>店铺LOGO</span>
					<em  v-if="Shop != null">
						<el-upload
						  class="avatar-uploader"
						  action="https://jsonplaceholder.typicode.com/posts/"
						  :show-file-list="false" :on-change="getFile" :limit="1" :auto-upload="false">
						  <img v-if="Shop.Logo" :src="Shop.Logo" class="avatar">
						  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</em>
				</li>
				
				<li>
					<span>店铺状态</span>
					<em  v-if="Shop != null">
						<el-select placeholder="设置账号状态" v-model="Shop.Status">
							<el-option label="删除" :value="0" disabled></el-option>
							<el-option label="正常" :value="50"></el-option>
						    <el-option label="禁用" :value="10"></el-option>
					    </el-select>
					</em>
				</li>
				
				<li>
					<span>推荐星级</span>
					<em  v-if="Shop != null">
						<el-input type="text" v-model="Shop.Star" style="width: 120px;"></el-input>
						<i style="margin-left: 10px;">必须为数字</i>
					</em>
				</li>
				
				<li v-if="Shop != null && Shop.Id != 'add'">
					<span>创建时间</span>
					<em>
						{{Shop.CreatedAt}}
					</em>
				</li>
				
				<li v-if="Shop != null && Shop.Id != 'add'">
					<span>最后修改时间</span>
					<em>
						{{Shop.UpdatedAt}}
					</em>
				</li>
				
				<li>
					<span></span>
					<em>
						<el-button v-if="Shop != null && Shop.Id != 'add'" @click="UpdateShop()" type="warning">提交修改</el-button>
						<el-button v-if="Shop != null && Shop.Id == 'add'" @click="UpdateShop()" type="warning">添加店铺</el-button>
					</em>
				</li>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import {Select,Option,Dialog,Upload} from 'element-ui'
	export default {
	  name: 'ShopInfo',
	  props: {
	  },
	  data() {
	      return {
			  Shop:null,
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少用户身份ID，页面无法工作')
			return
		}
		if(this.$route.params.Id == 'add'){
			this.InitShop()
			return
		}
		this.GetShop(this.$route.params.Id)
	  },
	  methods:{
		  getFile(file, fileList) {
		  				this.getBase64(file.raw).then(res => {
		  					this.uploadPic(res)
		  			    });
		  },
		  getBase64(file) {
		        return new Promise(function(resolve, reject) {
		          let reader = new FileReader();
		          let imgResult = "";
		          reader.readAsDataURL(file);
		          reader.onload = function() {
		            imgResult = reader.result;
		          };
		          reader.onerror = function(error) {
		            reject(error);
		          };
		          reader.onloadend = function() {
		            resolve(imgResult);
		          };
		        });
		      },
		  			  uploadPic(_b64){
		  				  let that = this
		  				  let data = {
		  				  	Service:'File',
		  				  	Class: 'File',
		  				  	Action: 'Base64Upload',
		  				  	Base64:_b64
		  				  }
		  				  this.$post(that.$store.getters.getApiHost,data)
		  				  .then((res) => {
		  				  	
		  				  	if(res.ErrorId != 0){
		  				  		that.$message(res.Msg)
		  				  		return
		  				  	}
		  				  	
		  				  	that.Shop.Logo = res.Data.Url
		  				  	
		  				  })
		  				  .catch(function (response) {
		  				  	that.DialogMsg = '网络请求错误'
		  				  })
		  			  },
		  InitShop(){
			this.Shop = {
				Id:'add',
				Name:'',
				Status:50,
				Star:1,
				Logo:''
			}
		  },
		    GetShop(_id){
		  		let that = this
		  		let data = {Service:'Shop',Class: 'Shop',Action: 'Get',Id:_id,}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				that.$message(res.Msg)
		  				return
		  			}
		  			that.Shop = res.Data
		  			
		  		})
		    },
			UpdateShop(){
				let that = this
				let data = {Service:'Shop',Class: 'Shop',Action: 'Update',Id:that.Shop.Id,Status:that.Shop.Status,Name:that.Shop.Name,Logo:that.Shop.Logo}
				if(that.Shop.Id == 'add'){
					data.Action = 'Add'
					data.Id = ''
				}
				this.$post(that.$store.getters.getApiHost,data)
				.then((res) => {
					that.$message(res.Msg)
					if(res.ErrorId == 0 && that.Shop.Id == 'add'){
						that.$Jump('/do/shop/shop_list')
					}else if(res.ErrorId == 0){
						that.GetShop(that.Shop.Id)
					}
				})
			},
	  }
	}
</script>

<style scoped>
.Shop{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Shop .Top{
	display: flex;
	align-items: center;
}
.Shop .Top .Left{
	
}
.Shop .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.Shop .List{
	margin-top: 20px;	
}
.Shop .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.Shop .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Shop .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.Shop .List .One li  em{
	flex: 1;
}
.Shop .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 64px;
    height: 64;
    line-height: 64px;
    text-align: center;
  }
  .avatar {
    width: 64px;
    height: 64px;
    display: block;
  }
</style>
